import React from 'react';
import './Topbottom.css'

function Topbottom(props) {
    const {aboutUs} = props;
    return (
        <div className="sectionServicescomplets pt-3">
            <div className='container container-seformer'>
                <div className="my-5 section-qui-sommes-nous">
                    <h2 className="titlequisommesnous">{aboutUs.title}</h2>
                    {/* <p>{aboutUs.description}</p> */}
                    <p>
                        Seformer.re est la transverse <strong className="text-bold">formation</strong> du site www.domtomjob.com.
                        Né d'un constat simple, le secteur de la <strong className="text-bold">formation</strong> est un secteur oublié mais primordiale aujourd'hui et en plein développement.
                        Nous avons décidé d'offrir à nos internautes et nos clients une plateforme de mise en relation, de partage, d'échange et de recherche.
                        Toutes les informations essentielles au secteur de la <strong className="text-bold">formation</strong>, des conseils, des fiches métiers seront regroupés sur notre site seformer.re.
                        Suivez-nous ici et sur nos réseaux sociaux à savoir: Facebook, Linkedin et Instagram de DomTomJob et de Seformer.re.
                        Seformer.re est un site appartenant au groupe Antenne Réunion, vous pouvez nous contacter via l'onglet Contact.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Topbottom;