import React from "react"
import images from "../../assets/images"
import "./Textemploy.css"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ImageLazy from "../ImageLazy/ImageLazy"

function Textemploy(props) {
  const { shortDescription, shortDescriptionImage } = props
  return (
    <div className="section-Textemploy pt-100 pb-2">
      <div className="container container-seformer">
        <div className="row">
          <div className="col-md-6 text-center">
            <h3>{shortDescription.title}</h3>
            {/* <p>{shortDescription.description}</p> */}
            <p>
              Le secteur de la <strong className="text-bold">formation</strong>{" "}
              est très vaste et parmi les nombreuses possibilités, il est
              parfois difficile de s'y retrouver. Vous venez d'être diplômé et
              hésitez entre plusieurs secteurs d'activités, privilégiez ceux qui
              vous passionnent ou pour lesquels vous avez des aptitudes. Il vous
              sera toujours plus facile d'apprendre et d'être bon voir excellent
              dans une voie qui vous correspond. Si vous avez des doutes, faites
              des tests de personnalités ou un bilan de compétences. Vous
              pourriez être surpris par les résultats. Retrouvez toutes les{" "}
              <strong className="text-bold">formations</strong> existantes en
              naviguant sur notre site.
            </p>
            <button
              type="submit"
              className="btn btn-primary btn-all-formation mt-5 mb-5"
            >
              <Link className="cta" to={"/annonces"}>
                {shortDescription.button_text}{" "}
                <img
                  src={images.litleSearchIcone}
                  alt="icone search"
                  className="img-fluid icone-little-search"
                />
              </Link>
            </button>
          </div>
          <div className="col-md-6 img-center-mobile">
            <Img
              fluid={shortDescriptionImage.childImageSharp.fluid}
              alt="texte employé dans la composition"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              type="submit"
              className="btn btn-notvisible btn-primary btn-all-formation mb-5"
            >
              <Link className="cta" to={"/annonces"}>
                {shortDescription.button_text}{" "}
                <ImageLazy
                  src={images.litleSearchIcone}
                  alt="icone search"
                  className="img-fluid icone-little-search"
                />
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Textemploy
