import React, { useEffect, useState } from "react"
import images from "../../assets/images"
import "./Niveauformation.css"
import { Link } from "gatsby"
import { mediaUrl, slugify } from "../../services/helpers"
import ImageLazy from "../ImageLazy/ImageLazy"
import Img from "gatsby-image"

const getImage = (images, name) => {
  if (!images.length) return
  const image = images.filter(
    item => btoa(item.name) === btoa(`${name}--LEVEL`)
  )
  const noImage = images.filter(item => /\+4/.test(item.name))
  return (
    (image && image.length && image[0].childImageSharp.fluid) ||
    (noImage && noImage.length && noImage[0].childImageSharp.fluid) ||
    null
  )
}

function Niveauformation(props) {
  const { levels, levelImages, bannerMiddle, bannerMiddleLink } = props
  const [data, setData] = useState([])
  useEffect(() => {
    if (levels.length) {
      const fromElement = levels[5]
      const nfLevels = levels.slice(0) // use a copy for processing
      nfLevels.splice(0, 1)
      nfLevels.splice(0, 0, nfLevels.splice(4, 1)[0])
      setData(nfLevels)
    }
  }, [levels])

  return (
    <div className="desktop">
      {bannerMiddle && (
        <div className="container-fluid my-5">
          <div className="row justify-content-center">
            <a
              href={bannerMiddleLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Img
                fluid={bannerMiddle.childImageSharp.fluid}
                alt="icone search"
                className="img-fluid my-5 icone-little-search imgPub"
                imgStyle={{ position: "auto" }}
              />
            </a>
          </div>
        </div>
      )}
      <div className="section-niveauFormation py-5">
        <div className="container container-seformer">
          <div className="row">
            <h2 className="text-center title-section mx-auto mb-4">
              Par niveau <span>de formation</span>{" "}
              <ImageLazy
                src={images.livreniveaudeformation}
                alt="icone niveau de formation"
                rel="noreferrer noopener"
              />
            </h2>
          </div>
          <div className="row">
            {data.map((item, key) => {
              return (
                <div key={"_" + key} className="col-md-3 niveauFormation">
                  <Img
                    fluid={getImage(levelImages, item.name)}
                    className="bg-level"
                  />
                  <Link to={`/annonces?level=${slugify(item?.name) || ""}`}>
                    <h3 className="Niveauformation-Titre">{item?.name}</h3>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Niveauformation
