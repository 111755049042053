import { Link } from "gatsby"
import React from "react"
import clip from "text-clipper"
import Img from "gatsby-image"

import images from "../../assets/images"
import {
  generateTrainingSlug,
  mediaUrl,
  showLocality2,
  validateDuration,
} from "../../services/helpers"

import Wrapper from "../Wrapper/Wrapper"

import "./Alaune.css"
import ImageLazy from "../ImageLazy/ImageLazy"

function Alaune(props) {
  const { trainings, bannerTop, bannerTopLink } = props
  return (
    <div className="pt-5 fond-gris section-alaune">
      <div className="container container-seformer">
        <div className="row">
          <h2 className="text-center title-section mx-auto mb-5">
            FORMATIONS <span>À LA UNE</span>{" "}
            <ImageLazy src={images.titreLivre} alt="Formations à la une" />
          </h2>
        </div>
        <div className="row mb-85 mt-2">
          {trainings
            .filter(item => validateDuration(item.start_date, item.duration))
            .map((training, key) => {
              return (
                key < 6 && (
                  <Wrapper key={"_" + key}>
                    <Link
                      to={generateTrainingSlug(training)}
                      className="cta col-lg-4 mb-5"
                      title={
                        training.title +
                        " - " +
                        training.types[0]?.name +
                        " - " +
                        training.region?.name +
                        " " +
                        training.cities[0]?.name
                      }
                    >
                      <div className="logoFormation">
                        <ImageLazy
                          fluid={training?.agency.logoSharp}
                          src={mediaUrl(
                            {
                              url:
                                training?.agency?.logo?.url ||
                                training?.info_agency?.logo[0] ||
                                training?.agency?.logo?.url ||
                                images.noimage,
                            },
                            null,
                            "resize"
                          )}
                          alt={
                            training.title +
                            " - " +
                            training.types[0]?.name +
                            " - " +
                            training.region?.name +
                            " " +
                            training.cities[0]?.name
                          }
                          className="icone-logo icone-little-search"
                        />
                      </div>
                      <div className="formation-a-la-une">
                        <h3 className="titleFormation" title={training.title}>
                          {clip(training.title, 50, { maxLines: 2 })}
                          <span className="lepost"> </span>
                        </h3>
                        <ul className="infoFormation">
                          <li className="alternance">
                            {training.types[0]?.name || "NC"}
                          </li>
                          <li className="lieux">
                            {clip(
                              showLocality2(training.region, training.cities),
                              50
                            )}
                          </li>
                        </ul>

                        <div className="btn-voir-formation">
                          <button>
                            Voir l’annonce{" "}
                            <ImageLazy
                              src={images.litleSearchIcone}
                              alt="icone search"
                              className="img-fluid icone-little-search"
                            />
                          </button>
                        </div>
                      </div>
                    </Link>
                  </Wrapper>
                )
              )
            })}
        </div>
      </div>
      {bannerTop && (
        <div className="container container-seformer my-5">
          <div className="d-flex justify-content-center">
            <a href={bannerTopLink} target="_blank" rel="noreferrer noopener">
              <Img
                fluid={bannerTop.childImageSharp.fluid}
                alt="Pub"
                className="img-fluid icone-little-search mb-4 imgPub"
                imgStyle={{ position: "auto" }}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Alaune
