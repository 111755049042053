import React from "react"
import Img from "gatsby-image"
import images from "../../assets/images"
import "./Slideshows.css"
import Moteurderecherche from "./Moteurderecherche"
import ImageLazy from "../ImageLazy/ImageLazy"

function Slideshows(props) {
  const { types, cities, regions, sectors, trainings, bgImage } = props
  return (
    <div className="position-relative">
      <Img
        className="Slide slideblure"
        fluid={bgImage.childImageSharp.fluid}
        style={{ width: "100%" }}
        loading="auto"
      />
      <div className="slidecontenu">
        <div className="container container-seformer">
          <div className="row">
            <div className="col-md-1 text-right">
              <ImageLazy
                src={images.searchSlideHp}
                alt="icone search"
                className="img-fluid icone-search"
              />
            </div>
            <div className="col-md-11 mb-4">
              <h1 className="titleSlide">
                <p className="slideTitre">
                  Trouvez une&nbsp;formation à&nbsp;La&nbsp;Réunion,
                  en&nbsp;Métropole
                </p>
                <p className="SlideTitreAvecBg">Ou dans les DROM-COM</p>
              </h1>
            </div>
          </div>
          <div className="row mt-4 align-items-center">
            <div className="col-md-8">
              <Moteurderecherche
                types={types}
                cities={cities}
                regions={regions}
                sectors={sectors}
                trainings={trainings}
              />
            </div>
            <div className="col-md-4 mt-4"> </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slideshows
