import React from "react"
import "./Domainedeformations.css"
import "./style.css"
import images from "../../assets/images"
import { Link } from "gatsby"
import { slugify } from "../../services/helpers"
import { BackgroundLazyImage } from "../ImageLazy/ImageLazy"

function Domainedeformations(props) {
  const { sectors } = props

  return (
    <BackgroundLazyImage
      className="section-domainedeformation desktop"
      src={images.domaineForm}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-5 justify-content-center">
            <BackgroundLazyImage
              type="h2"
              className="title-domainedeformation"
              src={images.livredomainedeformation}
            >
              Domaines
              <br />
              de formation
            </BackgroundLazyImage>
          </div>
          <div className="col-md-7 colsearch">
            <div className="cloud">
              <ul className="listeMotcle">
                {sectors.map((item, key) => {
                  if (key <= 10) {
                    let names = item.name.trim().split("-")
                    return (
                      <li key={"li_" + key} className="liste-mot-cle">
                        <Link
                          to={`/annonces?sector=${slugify(item.name) || ""}`}
                        >
                          <h3>
                            {names.map((item, key) => (
                              <span key={"span_" + key} className="d-block">
                                {item.toLowerCase()}
                              </span>
                            ))}
                          </h3>
                        </Link>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BackgroundLazyImage>
  )
}

export default Domainedeformations
