import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Dropdown from "react-overlays/Dropdown"
import { useDropdownMenu, useDropdownToggle } from "react-overlays"
import { Link } from "gatsby"
import _ from "underscore"

import "./Slideshows.css"
import images from "../../assets/images"
import { slugify } from "../../services/helpers"

import ImageLazy from "../ImageLazy/ImageLazy"

const MenuContainer = styled("div")`
  display: ${p => (p.show ? "flex" : "none")};
  min-width: 100%;
  position: absolute;
  z-index: 1000;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
`

function Moteurderecherche({ trainings, types, regions, sectors }) {
  const [showType, setType] = useState(false)
  const [showRegion, setRegion] = useState(false)
  const [showSector, setSector] = useState(false)

  const [type, setTypeValue] = useState()
  const [region, setRegionValue] = useState((regions && regions[2]) || {})
  const [sector, setSectorValue] = useState([])

  const defaultTypes = [{ id: 0, name: "une formation" }, ...types]
  const defautlSectors = [{ id: 0, name: "tous les secteurs" }, ...sectors]

  const Menu = ({ role, items, onToggle, onChange }) => {
    const { show, props } = useDropdownMenu({
      flip: true,
      offset: [0, 8],
    })
    return (
      <MenuContainer
        {...props}
        role={role}
        show={show}
        items={items}
        className="conteneur-menu"
      >
        <div className="listebutton">
          {items &&
            items.map((item, key) => {
              return (
                <button
                  key={"_" + key}
                  type="button"
                  onClick={() => {
                    onToggle && onToggle()
                    onChange && onChange(item)
                  }}
                  className="text-right hover:bg-brand-100 px-6 py-1"
                >
                  {item.name.toLowerCase()}
                </button>
              )
            })}
        </div>
      </MenuContainer>
    )
  }
  const Toggle = ({ id, children }) => {
    const [props, { toggle }] = useDropdownToggle()
    return (
      <button
        type="button"
        className="btn btn-formation"
        id={id}
        {...props}
        onClick={toggle}
      >
        {children}
      </button>
    )
  }
  const DropdownButton = ({
    id,
    show,
    onToggle,
    drop,
    alignEnd,
    title,
    role,
    items,
    onChange,
  }) => (
    <Dropdown
      show={show}
      onToggle={onToggle}
      drop={drop}
      alignEnd={alignEnd}
      itemSelector="button:not(:disabled)"
      items={items}
    >
      {({ props }) => (
        <div {...props} className="relative inline-block contenu-recherche">
          <Toggle id={id}>{title}</Toggle>
          <Menu
            role={role}
            items={items}
            onChange={onChange}
            onToggle={onToggle}
          />
        </div>
      )}
    </Dropdown>
  )
  const ButtonToolbar = styled("div")`
    & > * + * {
      margin-left: 12px;
    }
  `
  const [filteredTrainings, setFilteredTrainings] = useState(trainings)

  const closeAllMenu = () => {
    setRegion()
    setType()
    setSector()
  }

  const resetField = (action, value) => {
    action === "type" &&
      slugify(value.name) === slugify("une formation") &&
      setTypeValue()
    // action === "region" &&
    //   slugify(value.name) === slugify("la réunion") &&
    //   setRegionValue()
    action === "sector" &&
      slugify(value.name) === slugify("tous les secteurs") &&
      setSectorValue([])
  }

  const filterTrainings = () =>
    trainings.filter(training => {
      //training type
      const inType = (training, type) => {
        if (type === "" || type === undefined) return true
        else {
          let found = false
          training.types.map((item, key) => {
            if (slugify(type.name) === slugify(item.name)) found = true
          })
          return found
        }
      }

      //training region
      const inRegion = (training, region) => {
        if (region === "" || region === undefined) return true
        else {
          let found = false
          if (
            training.region &&
            slugify(training.region.name) == slugify(region.name)
          ) {
            found = true
          }
          return found
        }
      }

      //training sector
      const inSector = (training, filterSector) => {
        if (!filterSector.length) {
          return true
        }
        const sectors = (training?.sectors && training?.sectors) || []
        return !!_.intersection(
          (Array.isArray(sectors) && sectors || [sector]).map(item => item.name),
          ((filterSector && filterSector) || []).map(item => item.name)
        ).length
      }

      return (
        inType(training, type) &&
        inRegion(training, region) &&
        inSector(training, sector)
      )
    })

  useEffect(() => {
    const init = async () => {
      let filteredData = await filterTrainings()
      setFilteredTrainings(filteredData)
    }
    init()
  }, [type, region, sector])

  const onChangeSector = e => {
    setSectorValue([e])
    resetField("sector", e)
  }

  return (
    <div className="contenuSearch p-4">
      <form
        className="form-inline justify-content-center"
        action={"/annonces"}
        method={"GET"}
      >
        <div className="form-group row mb-3 col-12 justify-content-center">
          <label
            htmlFor="jecherche"
            className="col-sm-3 col-form-label justify-content-end"
          >
            Je recherche
          </label>
          <div className="col-sm-5">
            <ButtonToolbar
              className={
                showType ? "dropdown-recheche active" : "dropdown-recheche"
              }
            >
              <DropdownButton
                id={"dropdown_type"}
                show={showType}
                items={defaultTypes}
                onChange={e => {
                  setTypeValue(e)
                  resetField("type", e)
                }}
                onToggle={nextShow => {
                  closeAllMenu()
                  setType(nextShow)
                }}
                title={type?.name.toLowerCase() || `une formation`}
              />
            </ButtonToolbar>
          </div>
        </div>
        <div className="form-group row mb-3 col-12">
          <div className="col-sm-5 dropdown_region_case">
            <ButtonToolbar
              className={
                showRegion ? "dropdown-recheche active" : "dropdown-recheche"
              }
            >
              <DropdownButton
                id="dropdown-region"
                show={showRegion}
                items={regions}
                onChange={e => {
                  setRegionValue(e)
                  resetField("region", e)
                }}
                onToggle={nextShow => {
                  closeAllMenu()
                  setRegion(nextShow)
                }}
                title={region?.name.toLowerCase() || "à la réunion"}
              />
            </ButtonToolbar>
          </div>
          <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
            dans
          </label>
          <div className="col-sm-5">
            <ButtonToolbar
              className={
                showSector
                  ? "dropdown-recheche menudropdown_sector active"
                  : "dropdown-recheche"
              }
            >
              <DropdownButton
                id="dropdown_sector"
                show={showSector}
                items={defautlSectors}
                onChange={onChangeSector}
                onToggle={nextShow => {
                  closeAllMenu()
                  setSector(nextShow)
                }}
                title={
                  (sector.length && sector[0].name.toLowerCase()) ||
                  `tous les secteurs`
                }
              />
            </ButtonToolbar>
          </div>
        </div>
        <div className="form-group row col-12 justify-content-center flex-column">
          <Link
            to={`/annonces${
              region?.name ? `/${slugify(region?.name)}` : ""
            }?sector=${
              (sector.length && sector.map(s => slugify(s.name)).join("/")) ||
              ""
            }&type=${(type && slugify(type.name)) || ""}`}
          >
            <div className="btn btn-primary">
              {(filteredTrainings.length > 1 &&
                `Voir les ${filteredTrainings.length} annonces`) ||
                `Voir l'annonce`}{" "}
              <ImageLazy
                src={images.litleSearchIcone}
                alt="icone search"
                className="img-fluid icone-little-search"
              />
            </div>
          </Link>
          <button className="btn-avance">Recherche avancée</button>
        </div>
      </form>
    </div>
  )
}

export default Moteurderecherche
