import React from "react"
import images from "../../assets/images"
import "./Domainedeformations.css"
import Carousel from "react-elastic-carousel"
import { slugify } from "../../services/helpers"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ImageLazy from "../ImageLazy/ImageLazy"

function DomainedeformationsMobile(props) {
  const { sectors, bannerMiddle, bannerMiddleLink } = props

  return (
    <>
      {bannerMiddle && (
        <div className="container container-seformer">
          <div className="d-flex justify-content-center">
            <a
              href={bannerMiddleLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Img
                fluid={bannerMiddle.childImageSharp.fluid}
                alt="icone search"
                className="img-fluid mb-5 icone-little-search"
                imgStyle={{ position: "auto" }}
              />
            </a>
          </div>
        </div>
      )}
      <div className="section-domainedeformation mobile">
        <div className="container container-seformer">
          <div className="row">
            <div className="col-md-12 justify-content-center">
              <h2 className="title-domainedeformation">
                Domaines<span>de formation</span>{" "}
                <ImageLazy
                  src={images.iconedomainedeformation}
                  alt="icone domaine de formation"
                />
              </h2>
            </div>
          </div>

          <div className="row domainedeformationmobile">
            <ul className="listeMotcle">
              <Carousel disableArrowsOnEnd>
                {sectors.map((item, key) => (
                  <li key={"_" + key} className="liste-mot-cle">
                    <Link to={`/annonces?sector=${slugify(item.name) || ""}`}>
                      <span className="d-block">{item.name.toLowerCase()}</span>
                    </Link>
                  </li>
                ))}
              </Carousel>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default DomainedeformationsMobile
