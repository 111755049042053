import React from "react"
import images from "../../assets/images"
import "./CentresdeFormations.css"
import Carousel from "react-elastic-carousel"
import { mediaUrl, slugify } from "../../services/helpers"
import { Link } from "gatsby"
import ImageLazy from "../ImageLazy/ImageLazy"
import Wrapper from "../Wrapper/Wrapper"

function CentresdeFormationsMobile(props) {
  const { agencies } = props
  return (
    <div className="cetredeformations  my-5">
      <div className="container container-seformer">
        <div className="row">
          <h2 className="text-center title-section mx-auto mb-4">
            Nos Centres <span>de Formation</span>{" "}
            <ImageLazy
              src={images.iconeNosFormations}
              alt="Icone Nos Formations"
            />
          </h2>
        </div>

        <div className="row cetredeformationsmobile">
          <Carousel
            disableArrowsOnEnd
            enableAutoPlay={true}
            transitionMs={2000}
            autoPlaySpeed={4500}
            itemsToShow={6}
            pagination={false}
            enableSwipe={false}
            enableMouseSwipe={false}
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 550, itemsToShow: 1 },
              { width: 750, itemsToShow: 3 },
              { width: 991, itemsToShow: 4 },
              { width: 1200, itemsToShow: 6 },
            ]}
          >
            {agencies.map((item, key) => {
              const agencyLogo = mediaUrl(item?.logo, item?.logo, "resize")
              const length = item?.articles?.aggregate?.count || 0
              return (
                <Wrapper key={"_" + key}>
                  <div className="imageconteneur">
                    <ImageLazy
                      className="icon-logo"
                      src={agencyLogo || images.noimage}
                      alt={item.name}
                    />
                  </div>
                  <Link
                    to={`/annonces?agency=${slugify(item.name) || ""}`}
                    title={length + " formations " + item.name}
                    className="nombreFormations"
                  >
                    {length} formations
                  </Link>
                </Wrapper>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default CentresdeFormationsMobile
