import React from "react"
import images from "../../assets/images"
import "./Niveauformation.css"
import Carousel from "react-elastic-carousel"
import { mediaUrl, slugify } from "../../services/helpers"
import { Link } from "gatsby"
import ImageLazy, { BackgroundLazyImage } from "../ImageLazy/ImageLazy"

function NiveauformationMobile(props) {
  const { levels } = props
  return (
    <div className="section-niveauFormation mobile">
      <div className="container mt-5 container-seformer">
        <div className="row">
          <h2 className="text-center title-section mx-auto mb-4">
            Par niveau <span>de formation</span>{" "}
            <ImageLazy
              src={images.livreniveaudeformation}
              alt="livre niveau de formation"
            />
          </h2>
        </div>

        <div className="row sectionniveauFormationmobile">
          <Carousel disableArrowsOnEnd>
            {levels.map((item, key) => {
              if (item) {
                const levelImage = mediaUrl(item.image, item.image, "original")
                return (
                  <div key={"_" + key} className="col-md-3">
                    <BackgroundLazyImage
                      className="niveauFormation"
                      src={levelImage}
                    >
                      <Link to={`/annonces?level=${slugify(item.name) || ""}`}>
                        <h3 className="Niveauformation-Titre">{item.name}</h3>
                      </Link>
                    </BackgroundLazyImage>
                  </div>
                )
              }
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default NiveauformationMobile
